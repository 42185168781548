.non-ideal-state {
    display: flex;
    min-height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.non-ideal-state .material-icons {
    font-size: 64px;
    color: #cacedb;
}

.non-ideal-state span {
    margin-top: 12px;
    opacity: .8;
}
