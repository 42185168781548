.content-container {
    padding-top: 20px;
    padding-bottom: 20px;
    height: calc(100vh - 120px);
}

.grid {
    display: grid;
    grid-gap: 1em;
}

.grid.g2 {
    grid-template-columns: 1fr 1fr;
}

.grid.g3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.card {
    position: relative;
    overflow: hidden;
}

.card.inter {
    transition: all .4s ease-in-out;
    opacity: 1;
}

.card.inter.selected {
    transform: scale(1.04);
    background: #f5faff;
}

.card.inter:hover {
    cursor: pointer;
    box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 5%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 5%), 0 0.25rem 0.53125rem rgb(90 97 105 / 6%), 0 0.125rem 0.1875rem rgb(90 97 105 / 5%);
}

.card-header {
    background: transparent;
}

.card-body.compact {
    padding: 1rem 1.875rem;
}

.card.gallery-card .card-body {
    padding-top: 0 !important;
}

.card-emb {
    position: absolute;
    background-position: left center;
    background-repeat: no-repeat;
    transform: rotate(-7deg);
    opacity: .28;
    right: -30px;
    width: 50%;
    height: 100%;
    background-size: 160%;
    z-index: 0;
    transition: all .4s ease-in-out;
}

.card.inter:hover .card-emb {
    opacity: .44;
}

.card-header button {
    position: absolute;
    right: 1.875rem;
}

.item-col .card {
    margin-bottom: 1em;
}

.gallery-grid_item {
    height: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    opacity: .86;
    transition: all .4s linear;
    position: relative;
}

.gallery-grid_item .btn {
    opacity: 0;
    position: absolute;
    top: 4px;
    right: 4px;
}

.gallery-grid_item:hover .btn {
    opacity: 1;
}

.gallery-grid_item:hover {
    opacity: 1;
}

.gallery-grid_item.btn {
    position: relative;
}

.gallery-grid_item input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
}
.percentage {
    padding-left: 1rem;
    font-size: 0.75rem;
    position: relative;
}
.percentage::before {
    content: "";
    width: 0.75rem;
    height: 0.375rem;
    position: absolute;
    left: 0;
    top:50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-position: center center;
}

.increase {
    color: #17c671;
}
.increase::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMTdjNjcxIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gPHBhdGggZD0iTTcgMTRsNS01IDUgNXoiLz4gPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiA8L3N2Zz4=);
    background-repeat: no-repeat;
}
.decrease {
    padding-left: 1rem;
    font-size: 0.75rem;
    position: relative;
    color: #c4183c;
}
.decrease::before {

    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjYzQxODNjIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
}
.areaChart_canvas {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0.5;
}
._persons .periods {
    display: flex;
    flex-direction: column;

    padding: 1rem;
    border-bottom: 1px solid #e1e5eb;
}
._persons .periods__name {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}
._persons .periods:first-child {
    border-top: 1px solid #e1e5eb;
}
._persons .periods__item {
    display: flex;
    justify-content: space-around;
    text-align: center;
}
._persons div {
    box-shadow: none;
}
.periods__item .value {
    font-family: Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: #3D5170;
}

.navbar form {
    position: relative;
}

.navbar-suggestions {
    width: 100%;
    background: #fff;
    position: absolute;
    box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 10%), 0 0.9375rem 1.40625rem rgb(90 97 105 / 10%), 0 0.25rem 0.53125rem rgb(90 97 105 / 12%), 0 0.125rem 0.1875rem rgb(90 97 105 / 10%);
    top: 60px;
    left: 0;
    right: 0;
}

.navbar-suggestions_item {
    display: flex;
    height: 60px;
    border-bottom: 1px solid #eee;
    align-items: center;
}

.navbar-suggestions_item:last-child {
    border-bottom: none;
}

.navbar-suggestions_item .image {
    width: 44px;
    margin-left: 40px;
    overflow: hidden;
    text-align: center;
    margin-right: 6px;
}

.navbar-suggestions_item .image img {
    height: 31px;
}

.navbar-suggestions_item-body {
    display: flex;
    align-items: center;
    width: 50%;
}

.navbar-suggestions_item-body .text-muted {
    margin-left: 12px;
}
