.referencesContainer {
    height: calc(100vh - 120px);
    width: 1280px;
    box-sizing: border-box;
    padding: 30px;
    margin: 0 auto;
}

.referencesContainer .page-header {
    padding-top: 0 !important;
}

.referencesContainer > .row.content > .col {
    height: calc(100vh - 300px);
    overflow: hidden;
}

.referencesContainer > .row.content > .col.list-col {
    max-width: 300px;
}

.referencesContainer > .row.content > .col .grid {
    padding: 12px;
}

.referencesContainer .col-controls {
    padding-top: 8px;
}

.referencesContainer .col-controls .emblems {
    margin-top: 8px;
    height: 170px;
    width: 100%;
    margin-bottom: 8px;
    background: #292929;
}

.referencesContainer .col-controls .emblems img {
    border: 1px solid #eee;
}

.referencesContainer .col-controls .actions {
    display: flex;
    flex-direction: column;
}

.referencesContainer .col-controls .actions button {
    margin-bottom: 5px;
    width: 100%;
}

.references-upl-btn  {
    position: relative;
    width: 50px;
}

.references-upl-btn input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
}
.card.inter.selectedcard {
    background: #1adba2;
}

.card.inter.warningcard {
    background: #ffb400;
}

.card.inter.selectedcard.warningcard {
    background: #fb7906;
}
.clubfrom {
    display: flex;
    justify-content: space-between;
}
.clubpreview {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    max-width: 80px;
    max-height: 70px;
    font-size: 10px;
    position: absolute;
    top: 5px;
    right: 10px;
}
.clubpreview div {
    margin-bottom: 3px;
    text-align: right;
}
