.progress-wrap {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.progress-wrap .progress {
    width: 80%;
}

.progress-wrap span {
    margin-bottom: 12px;
    opacity: .78;
}
