.entity-btn {
    margin: 12px auto 0;
    max-width: 340px;
    position: relative;
    overflow: hidden;
}

.entity-btn > .btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: all .2s linear;
    transform: translateX(0);
    z-index: 2;
}

.entity-btn.clicked > .btn {
    transform: translateX(-110%);
}

.entity-btn .form-group {
    margin-bottom: 0;
}

.entity-btn .input-group-append {
    z-index: 1;
}

.entity-btn .material-icons {
    font-size: 14px;
}

.entity-btn .custom-checkbox {
    margin: 0;
}

.entity-btn .badge, .entity-btn .custom-select {
    border-radius: 0;
}

.entity-btn .custom-select {
    max-width: 110px;
}
